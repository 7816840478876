import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GridEditorDialog } from './grideditordialog/grid-editor-dialog.component';

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  public  grid: any[] = [];
  public headerWidth = [];

  @Input() set gridColumns(columns) {
    this._gridColumns = columns;
  };
  get gridColumns() {
    return this._gridColumns;
  }
  protected _gridColumns = 10;

  @Input() set gridRows(rows) {
    this._gridRows = rows;
  }
  get gridRows() {
    return this._gridRows;
  }
  protected _gridRows = 12;

  @Input() set data(d) {
    this._data = d;
// console.log(this._data)
    this.buildGrid();
    this.fillGrid(this._data);
    setTimeout(() => {
      this.setHeaderWidth();
    })
    // this.loadGrid(this._data);
  };
  protected _data = [];

  @Input() set settingsData(d) {
    this._settingsdata = d;
  };
  protected _settingsdata = [];

  @Input() set mode(m) {
    const modes = ['fulleditable', 'editable', 'readonly'];
    if(modes.includes(m)) {
      this._mode = m;
    } else {
      this._mode = 'fulleditable';
    }
  };
  protected _mode: string = 'fulleditable'; // readonly | editable | fulleditable

  @Output() onGridUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onGridLoad: EventEmitter<any> = new EventEmitter();
  

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  buildGrid() {
    // build grid
    this.grid = [];
    for(let i=1; i<=this._gridRows; i++) {
      let line =  Array(this._gridColumns).fill({text: null, settings: null});
      this.grid.push(line);
    }
  }

  fillGrid(data : any []) {
    if(data) {
      data.forEach((d, dindex) => {
        d.forEach((cell, cindex) => {
          if(typeof this.grid[dindex] !== 'undefined' && typeof this.grid[dindex][cindex] !== 'undefined') {
            this.grid[dindex][cindex] = {};
            this.grid[dindex][cindex]['text'] = cell.text || null;
            this.grid[dindex][cindex]['settings'] = cell.settings || null;
          }
        });
      })
    }
  }

  openEditor(cell, row_index, cell_index, withSettings: false) {
    // header is editable only in mode = 'fulledit'
    // if((row_index == 0 && this._mode === 'editable') || this._mode === 'readonly' || (this._mode === 'editable' && !this.isCellEditable(cell, row_index, cell_index)) ) {
    if(this._mode === 'readonly' || (this._mode === 'editable' && !this.isCellEditable(cell, row_index, cell_index)) ) {
      return;
    }
    
    let editorPlaceholder = null;
    if(this.isCellEditable(cell, row_index, cell_index) && this._mode === 'editable' && this._settingsdata) {
      editorPlaceholder = this._settingsdata[row_index][cell_index]['text'];
    }
    const dialogRef = this.dialog.open(GridEditorDialog, {
      data: {
        title: this.translate.instant('GRID.EDITOR_DIALOG.TITLE'),
        cell: cell,
        withSettings: withSettings,
        settings: {
          width: row_index === 0,
          // editable: row_index !== 0
          editable: true
        },
        gridMode: this._mode,
        placeholder: (editorPlaceholder && editorPlaceholder !== null) ? editorPlaceholder.replace(/<[^>]+>/g, ' ') : editorPlaceholder
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && typeof result.cell !== 'undefined') {
        this.grid[row_index][cell_index] = {
          text: result.cell.text || null
        }
        if(withSettings) {
          this.grid[row_index][cell_index]['settings'] = result.cell.settings || null; 
        }
        if(row_index == 0 && this._mode === 'fulleditable') {
          this.setHeaderWidth();
        }
        this.onGridUpdate.emit({ grid: this.grid });
      }
    });
  }

  loadGrid(data) {
    setTimeout(() => {
      this.buildGrid();
      this.fillGrid(data);
      this.setHeaderWidth();
      
      setTimeout(() => {
        this.onGridLoad.emit(this.grid);
      })
    });
  }

  isCellEditable(cell, row_index, cell_index): boolean {
    if(!cell) {
      return false;
    }
    let editable: boolean = true;
    let settings = cell.settings;
    
    if(this._settingsdata && this._settingsdata.length && this._settingsdata[row_index] && this._settingsdata[row_index][cell_index] && this._settingsdata[row_index][cell_index].settings){
      settings = this._settingsdata[row_index][cell_index].settings;
    }
    if(settings && typeof settings.editable !== 'undefined' && !settings.editable) {
      editable = false;
    }
    return editable;
  }

  cellWidth(cell, row_index, cell_index) {
    if(!cell) {
      return false;
    }
    let width = null;
    let settings = cell.settings;
    
    if(this._settingsdata && this._settingsdata.length && this._settingsdata[row_index] && this._settingsdata[row_index][cell_index] && this._settingsdata[row_index][cell_index].settings){
      settings = this._settingsdata[row_index][cell_index].settings;
    }

    if(settings && typeof settings.width !== 'undefined') {
      width = settings.width ;
    }
    return width;
  }

  setHeaderWidth() {
    let headerWidth = [];
    if(this.grid) {
      let header = this.grid[0];
      let originalWidth = [];
      header.forEach((h, index )=> {
        let celWidth = this.cellWidth(h, 0, index);
        originalWidth.push(celWidth)
      });

      let finalWidth = [];
      const nnullable = originalWidth.filter(element => {
        return element !== null;
      });
      const nullable = originalWidth.filter(element => {
        return element === null;
      });
      
      originalWidth.forEach(h => {
        if(h !== null) {
          finalWidth.push(h);
        } else {
          let w = 100 - nnullable.reduce((a, b) => a + b, 0);
          finalWidth.push(w / nullable.length);
        }
      });
      headerWidth = finalWidth;
    }
  
    this.headerWidth = headerWidth;
  }

  isRowVisible(row) {
    if(!row) {
      return false;
    }
    let isRowVisible = false;
    row.forEach(cell => {
      if(cell && cell.text && cell.text !== null && cell !== "") {
        isRowVisible = true;
        return;
      }
    });
    return isRowVisible;
  }

  // allow styling in innerHTML
  AllowStylingInnerHTML(htmlTextWithStyle) {
    if(htmlTextWithStyle) {
      return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
    }
    return null;
  }
}
