import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ValueList } from '../models/ValueList';

export interface ListApi {
  data: any[];
  meta: any;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected api_root = environment.api.root;
  protected downloads_root = environment.fileserver.downloads;
  protected data = { lists: {}, params: {} };
  
  constructor(
    private http: HttpClient
  ) { }

  getAsPromise(endpoint, params={}):any {
    return new Promise((resolve, reject) => {
      this.http
        .get<any[]>(`${this.api_root}/${endpoint}`, params)
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          console.log('RES=', error);
          reject(error)
        });
    });
  }

  get(endpoint, params={}): Observable<any> {
    return this.http.get(`${this.api_root}/${endpoint}`, {params:params});
  }

  getList(endpoint, params={}): Observable<ListApi> {
    return this.http.get<ListApi>(`${this.api_root}/${endpoint}`, {params:params});
  }

  delete(endpoint, params={}): Observable<any> {
    return this.http.delete(`${this.api_root}/${endpoint}`, {params:params});
  }

  putAsPromise(endpoint, data): Promise<any> {
    return new Promise((resolve, reject)=>{
      this.http
        .put(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          console.log('RES=', error);
          reject( this.parseErrors(error))
        });
      });
  }

  postAsPromise(endpoint, data): Promise<any> {
    return new Promise((resolve, reject)=>{
      this.http
        .post(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          console.log('RES=', error);
          reject( this.parseErrors(error))
        });
      });
  }

  deleteAsPromise(endpoint, data = null): Promise<any> {
    if (!data) {
      data = {};
    }
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${this.api_root}/${endpoint}`, { params: data })
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(this.parseErrors(error))
        });
    });
  }

  getExcelFile(endpoint, filter = null, filename = null) {
    return new Promise((resolve, reject) => {


      this.http.get(`${this.api_root}/${endpoint}`,{ 
       params: { filter: filter ? JSON.stringify( filter) : null },
       headers: new HttpHeaders({
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }), 
      responseType: 'blob'}
        )
        .toPromise()
        .then(data => {
          const file = new Blob([data], 
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            );
          const fileURL = window.URL.createObjectURL(file);
          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
            element.click();
          }else{
            window.open(fileURL);
          }
          resolve(true);
        }).catch(error => {
          reject(this.parseErrors(error))
        });
     
    });
  }

  getFileFromEvent(event) {
        return new Promise((resolve, reject) => {
          this.http.get(`${this.downloads_root}/${event.uri}`,{ 
           headers: new HttpHeaders({
            'Content-Type': event.mime,
            }), 
          responseType: 'blob'}
            )
            .toPromise()
            .then(data => {
              const file = new Blob([data], 
                { type: event.mime }
                );
              const fileURL = window.URL.createObjectURL(file);
              const element = document.createElement('a');
              element.href = URL.createObjectURL(file);
              if (event.filename) {
                element.download = event.filename;
                element.click();
              } else {
                window.open(fileURL);
              }
              resolve(true);
            }).catch(error => {
              console.log('excel was not downloaded!!!!!')
              reject(this.parseErrors(error))
            });
         
        });
      }


  parseErrors(data) {
    let ret=data.error;
    ret.fields = {};
    if  (data.error.errors && data.error.errors.length>0) {
      data.error.errors.forEach( err => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }

  getListData(name): Promise<ValueList> {

    return new Promise((resolve, reject) => {
      if (this.data.lists[name]) {
        resolve(this.data.lists[name]);
        return;
      }

      this.get(`lists/${name}`, {})
        .toPromise()
        .then(res => {
          let list = new ValueList(res.data);
          this.data.lists[list.listname] = list;
          resolve(list);
        })
        .catch(err => {
          resolve(new ValueList())
        })
    });
  }

}
