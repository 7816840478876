import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'evaluation-description',
  templateUrl: './evaluation-description.component.html',
  styleUrls: ['./evaluation-description.component.scss']
})
export class EvaluationDescriptionComponent implements OnInit {

  @Input() content:string = null;

  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
  }

  // allow styling in innerHTML
  AllowStylingInnerHTML(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

}
