import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public pageTitle = '';

  protected main_menu_auth = [
    {
      type: 'item',
      label: 'STATIC.NAV.CLIENTS_PARTNERS',
      path: '/admin/clients',
      roles: ['super-admin'],
      hide_on_mobile: true,
    },
    {
      type: 'item',
      label: 'STATIC.NAV.CLIENTS',
      path: '/admin/clients',
      roles: [ 'partner'],
      hide_on_mobile: true,
    },
    {
      type: 'item',
      label: 'STATIC.NAV.USERS',
      path: '/admin/users',
      roles: ['super-admin', 'partner'],
      hide_on_mobile: true,
    },

    {
      type: 'item',
      label: 'STATIC.NAV.DASHBOARD',
      path: '/dashboard',
      roles: ['partner_as_client', 'director', 'manager', 'user'],
    },
    {
      type: 'item',
      label: 'STATIC.NAV.EVALUATIONS',
      path: '/evaluations',
      roles: ['partner_as_client', 'director', 'manager'],
    },
    {
      type: 'item',
      label: 'STATIC.NAV.MY_EVALUATIONS',
      path: '/my-evaluations',
      roles: ['user', 'manager'],
    },
    // {
    //   type: 'item',
    //   label: 'STATIC.NAV.MY_HISTORY',
    //   path: '/my-history',
    //   roles: ['user', 'manager'],
    // },
    
    {
      type: 'group',
      menuId: 'menuadmin',
      label: 'STATIC.NAV.ADMINISTRATION',
      icon: 'settings',
      roles: ['partner_as_client', 'director', 'manager'],
      items: [
        {
          type: 'item',
          label: 'STATIC.NAV.TEAMS',
          path: '/teams',
          icon: 'groups',
          roles: ['partner_as_client', 'director', 'manager'],
        },
        {
          type: 'item',
          label: 'STATIC.NAV.USERS',
          path: '/admin/users',
          icon: 'group',
          roles: ['partner_as_client'],
          hide_on_mobile: true,
        },
        {
          type: 'item',
          label: 'STATIC.NAV.INTERVIEW_CATEGORIES',
          path: '/interview-categories',
          icon: 'construction',
          roles: ['super-admin', 'partner_as_client'],
          hide_on_mobile: true,
        },
        {
          type: 'item',
          label: 'STATIC.NAV.BILAN_GRIDS',
          path: '/bilan-grids',
          icon: 'construction',
          roles: ['partner_as_client'],
          hide_on_mobile: true,
        },

      ],
    },

    {
      type: 'group',
      menuId: 'menuadmin',
      label: 'STATIC.NAV.SETTINGS',
      icon: 'settings',
      roles: ['super-admin'],
      hide_on_mobile: true,
      items: [
        {
          type: 'item',
          label: 'STATIC.NAV.INTERVIEW_CATEGORIES',
          path: '/interview-categories',
          icon: 'construction',
          roles: ['super-admin', 'partner_as_client'],
        },
        // {
        //   type: 'item',
        //   label: 'STATIC.NAV.PARAMETERS',
        //   path: '/admin/parameters',
        //   icon: 'tune',
        //   roles: ['super-admin'],
        // },
        // {
        //   type: 'item',
        //   label: 'STATIC.NAV.LISTS',
        //   path: '/admin/lists',
        //   icon: 'list',
        //   roles: ['super-admin'],
        // },
      ],
    }
  ];

  protected main_menu = [];

  protected profile_menu_auth = [
    {
      type: 'item',
      label: 'STATIC.NAV.PROFILE',
      icon: 'account_circle',
      click: () => {
        this.showUserDialog();
      },
    },
    {
      type: 'item',
      label: 'STATIC.NAV.MY_HISTORY',
      path: '/my-history',
      icon: "history",
      roles: ['user', 'manager'],
    },
    {
      type: 'item',
      label: 'STATIC.NAV.HELP_CENTER',
      icon: 'help_center',
      path: '/help',
    },
    { type: 'item', label: 'STATIC.NAV.ABOUT', icon: 'info', path: '/about' },
  ];
  protected profile_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  protected footer_menu_auth = [
    {
      type: 'item',
      label: 'STATIC.NAV.FOOTER.QUALITEAM',
      url: 'https://qualiteam.io',
      target: 'qualiteam',
    },
    {
      type:'separator'
    },
    {
      type: 'item',
      label: 'STATIC.NAV.FOOTER.TERMS',
      url: 'https://qualiteam.io/terms',
      target: 'qualiteam',
    },
    {
      type:'separator'
    },
    {
      type: 'item',
      label: 'STATIC.NAV.FOOTER.PRIVACY_POLICY',
      url: 'https://qualiteam.io/privacy',
      target: 'qualiteam',
    },
  ];
  protected footer_menu = [];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService: DataService
  ) {
    const self = this;
  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user && user.exists() ? this.main_menu_auth : this.main_menu;
        break;
      case 'footer':
        return user && user.exists() ? this.footer_menu_auth : this.footer_menu;
        break;
      case 'profile':
        return user && user.exists()
          ? this.profile_menu_auth
          : this.profile_menu;
        break;
    }
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '600px',
      autoFocus: false,
      disableClose: true,
      data: {
        title: title,
        item: user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.auth.loadCurrentUser();
    });
  }

  linkedinLogin() {
    this.dataService.getAsPromise('linkedin/login').then((res) => {
      console.log('LinkedIn login endpoint', res);
      location.href = res.url;
    });
  }

  linkedinAppLogin() {
    this.dataService.getAsPromise('linkedin/applogin').then((res) => {
      console.log('LinkedIn app login', res);
    });
  }
}
