import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';




const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { title: 'STATIC.NAV.DASHBOARD',  roles: ['super-admin','partner','partner_as_client','director','manager','user'], role_forward:{ 'super-admin': '/admin/clients', 'partner': '/admin/clients' /*, 'user':'/my-evaluations'*/ }},
    loadChildren: () => import('./modules/qualiteam/dashboard/dashboard.module').then(mod => mod.DashboardModule),
    
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { title: 'STATIC.NAV.DASHBOARD',  roles: ['super-admin','partner','partner_as_client','director','manager','user'], role_forward:{ 'super-admin': '/admin/clients', 'partner': '/admin/clients' /*, 'user':'/my-evaluations'*/ }},
    loadChildren: () => import('./modules/qualiteam/dashboard/dashboard.module').then(mod => mod.DashboardModule),

  },
  {
    path: 'my-evaluations',
    data: { roles: ['user'], title: 'STATIC.NAV.MY_EVALUATIONS' },
    loadChildren: () => import('./modules/qualiteam/my-evaluations/my-evaluations.module').then(mod => mod.MyEvaluationsModule),
  },
  {
    path: 'my-history',
    data: { roles: ['user'], title: 'STATIC.NAV.MY_HISTORY' },
    loadChildren: () => import('./modules/qualiteam/my-history/my-history.module').then(mod => mod.MyHistoryModule),
  },
  {
    path: 'interview-categories',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','partner_as_client'], title: 'STATIC.NAV.INTERVIEW_CATEGORIES' },
    loadChildren: () => import('./modules/qualiteam/interviewcategories/interviewcategories.module').then(mod => mod.InterviewCategoriesModule),
  },
  {
    path: 'bilan-grids',
    canActivate: [AuthGuard],
    data: { roles: ['partner_as_client'], title: 'STATIC.NAV.BILAN_GRIDS' },
    loadChildren: () => import('./modules/qualiteam/bilan-grids/bilan-grids.module').then(mod => mod.BilanGridsModule),
  },
  {
    path: 'evaluation',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin','partner_as_client', 'director', 'manager', 'user'], title: 'STATIC.NAV.EVALUATE' },
    loadChildren: () => import('./modules/qualiteam/evaluation/evaluation.module').then(mod => mod.EvaluationModule),
  },
  {
    path: 'evaluations',
    canActivate: [AuthGuard],
    data: { roles: ['partner_as_client', 'director', 'manager'], title: 'STATIC.NAV.EVALUATIONS' },
    loadChildren: () => import('./modules/qualiteam/evaluations/evaluations.module').then(mod => mod.EvaluationsModule),
  },
  {
    path: 'teams',
    canActivate: [AuthGuard],
    data: { roles: ['partner_as_client','director','manager'], title: 'STATIC.NAV.TEAMS' },
    loadChildren: () => import('./modules/qualiteam/teams/teams.module').then(mod => mod.TeamsModule),
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    data: { title: 'STATIC.NAV.HELP_CENTER' },
    loadChildren: () => import('./modules/about/about.module').then(mod => mod.AboutModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    data: { title: 'STATIC.NAV.HELP_CENTER' },
    loadChildren: () => import('./modules/help/help.module').then(mod => mod.HelpModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule),
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
