import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'new-evaluation-button',
  templateUrl: './new-eval-button.component.html',
  styleUrls: ['./new-eval-button.component.scss'],
})
export class NewEvaluationsButtonComponent implements OnInit {

  //@Input() user = null;
  @Input() set userId(userId: any) {  
    if(userId && this.auth.getCurrentUser().id != userId){
      this._userId = userId;
      this.getUser(this._userId).then(user=>{
        this.user = user;
      });
    }else{
      this._userId = null;
      this.user = null;
    }
  }

  @Input() team_id = null;

  public ready = null;
  public mode=null;
  public user = null;
  public _userId = null;

  constructor(public auth: AuthenticationService, public router:Router,public dataService:DataService) {

    this.auth.currentAuthUser.subscribe(user=>{
      if (user) {
        if (user.hasRole(['manager'])) {
          this.mode = 'manager';
        }
        else if(user.hasRole(['partner_as_client', 'director'])) {
          this.mode = 'director';
        }       
        this.ready = true;
      } else {
        this.ready = false;
      }      
    });
  }

  ngOnInit() {
    // if(this.user){
    //   this.user_name = this.user.firstname+' '+this.user.lastname;
    // }
  }

  go(mode ='user', on_user) {
    
    if(on_user){
      let role = null;
     if(this.user.roles.find(role=> role.name == 'user')){
        role = 'user';
      }else if(this.user.roles.find(role=> role.name == 'manager')){
        role = 'manager';
      } 

      if(this.team_id){
        this.router.navigate([`/evaluation/${role}/u/${this._userId}/${this.team_id}`]);
      }else{
        this.router.navigate([`/evaluation/${role}/u/${this._userId}/${this.user.team_id}`]);
      }

    }else{
      this.router.navigate([`/evaluation/${mode}`]);
    }   
  }

  getUser(user_id): Promise<any> {
     return this.dataService
        .getAsPromise(`qualiteam/dashboard/user/${user_id}`)
        .then((res) => {
          if (res && res.data) {
              return Promise.resolve(res.data);
         } else {
            return Promise.resolve([]);
          }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
  }
}