import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NgControl, AbstractControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject, Subscription } from 'rxjs';
import { startWith, map, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

export interface OptionColumns {
    id: string;        // column name used as id
    label: string|string[];      // column name used as label
    operator?: string; // operator for filter(for where clause): equal: =, start: like .%, end: like %. , contain: like %..%
};
const defaultOptionColumns: OptionColumns = {
    id: 'id',
    label: 'name'
};

export interface OptionItem {
    id: any;
    label: string;
};

@Component({
    selector: 'autocomplete-field',
    templateUrl: './autocomplete-field.component.html',
    styleUrls: ['./autocomplete-field.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: AutocompleteField },
    ],

})
export class AutocompleteField
    implements
    MatFormFieldControl<OptionItem>,
    ControlValueAccessor,
    OnInit,
    DoCheck {
    public ready = false;
    private sub : Subscription = null;
    public readyAsObservable = new Observable();

    initialValue = null;
    isLoading = false;
    noOptionMsg: boolean = false;
    _populated = false;
    parametersData = {};

    fieldCtrl = new UntypedFormControl();
    filteredItemsList: OptionItem[];
    selectedItem: OptionItem = null;
    itemsList: any = null;

    stateChanges = new Subject<void>();
    static nextId = 0;
    focused = false;
    errorState = true;
    controlType = 'autocomplete-field';

    getItemsList(){
      return this.itemsList;
    }
    
    @Input()
    readonly: boolean = false;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get value(): any | null {
        return this._value;
    }
    set value(val: any | null) {
        this.initValue(val);
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();
    }
    _value: any = null;

    @Input()
    set  endpoint(endpoint: string) {
        this._endpoint = endpoint;
    }
    _endpoint: string = null;

    @Input() set extraParameters(extraParameters) {
        this._extraParameters = extraParameters;
    }
    get extraParameters() {
        return this._extraParameters;
    }
    private _extraParameters = {};

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return !this._value || this._value.length == 0;
    }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.fieldCtrl.disable() : this.fieldCtrl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input() set OptionsColumns(columns: OptionColumns) {
        if(columns) {
            this._OptionsColumns = columns;
        }
    };
    _OptionsColumns: OptionColumns = defaultOptionColumns;

    @Input() numberOfOptions = null;

    @Input() objectOption = false;

    @Input() clearable: boolean = false;

    @Input() searchMinLength = 2;
    @Input() searchTimeout = 300;
    @Input() oneRequest = false;
    public requestNb = 0;

    @Output() onClear: EventEmitter<any> = new EventEmitter();
    @Output() onValueChanges: EventEmitter<any> = new EventEmitter();

    @Output() inputHasValue: EventEmitter<any> = new EventEmitter();
    @Output() dataHasLoaded: EventEmitter<any> = new EventEmitter();

    @HostBinding() id = `autocomplete-field-${AutocompleteField.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @ViewChild('autocompleteInput', { static: false }) autocompleteInput: ElementRef<HTMLInputElement>;
    constructor(
        @Optional() @Self() public ngControl: NgControl,
        fb: UntypedFormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private dataService: DataService,
        private translate: TranslateService,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            // mark the input as touched just after the input was focused
            if(this.focused) {
                this.onTouch();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }


    ngOnInit() {
        if(this.readonly) {
            this.fieldCtrl.disable()
        }

        this.sub = this.fieldCtrl.valueChanges
            .pipe(
                debounceTime(this.searchTimeout),
                tap(() => {
                // this.itemsList = [];
                // this.isLoading = true;
                }),
                switchMap(value => {
                    this.onValueChanges.emit(value);
                    let canSearch = false;
                    if(!value || !this.searchMinLength || (this.searchMinLength && (value.length >= this.searchMinLength || typeof(value.length) == 'undefined'))) {
                        canSearch = true;
                    }
                    if(!value) {
                        this.selectedItem = null; 
                        this.updateValue();
                    }

                    if(canSearch) {
                        // this.itemsList = [];
                        this.isLoading = true;

                        let params = {
                            id: this._OptionsColumns.id,
                            label: Array.isArray(this._OptionsColumns.label) ? JSON.stringify(this._OptionsColumns.label) : this._OptionsColumns.label
                        };
                        if(this.numberOfOptions) {
                            params['per_page'] = this.numberOfOptions;
                        }

                        if(value) {
                            let selectedValue =  value.label ? value.label.trim().toLowerCase() : value;
                            let f = {
                                operator: this._OptionsColumns.operator,
                                value: selectedValue
                            };
                            params['filter'] =  JSON.stringify(f);
                        }
                        if(this._extraParameters) {
                            params['parameters'] = JSON.stringify(this._extraParameters);
                        }

                        this.parametersData = {
                            params: params
                        };
                        if(this.initialValue) {
                            let ret = this.initialValue;
                            this.initialValue = null;
                            return ret;
                        } else {
                            this.initialValue = null;
                            if(!this.oneRequest || this.requestNb == 0) {
                                this.itemsList = [];

                                let url = this._endpoint.indexOf('/') != -1 ? this._endpoint : `lists/${this._endpoint}`;
                                return this.dataService.getAsPromise(url, this.parametersData);
                            } else {
                                this._localFilter(value);
                                return this.itemsList;
                            }
                        }
                    } else {
                        return [];
                    }
                })
            )
            .subscribe(data => {
                if(!this.oneRequest || this.requestNb == 0) {
                    if(data) {
                        if(data['data']) {
                            this.itemsList = data['data'];
                            this.requestNb ++;
                        } else {
                            this.itemsList = [data];
                        }
                        if(!this.itemsList.length) {
                            this.noOptionMsg = true
                        } else {
                            this.noOptionMsg = false
                        }
                    }

                    this._filter();
                }
                this.isLoading = false;
                this.ready = true;
                this.dataHasLoaded.emit(true);
            }
        );

        this.loadField();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);

        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    private _filter(value: any = null): OptionItem[] {
        this.filteredItemsList =  this.itemsList;
        let ret =  this.itemsList
        return ret;
    }

    private _localFilter(label: any): any[] {
        let ret = [];
        if (!label || (label && typeof label != 'string') ) {
            if(!label) {
                ret =  _.filter(this.itemsList,  (o)=> {
                    return o;
                });
            }
            if(label && typeof label != 'string') {
                let operator = this._OptionsColumns.operator
                const filterValue = label.label.toLowerCase();
                ret = this.filter(operator,filterValue);
            }
        } else {
            let operator = this._OptionsColumns.operator
            const filterValue = label.toLowerCase();
            ret = this.filter(operator,filterValue);
        }

        this.filteredItemsList = ret;
        if (!ret.length) {
            this.noOptionMsg = true;
        } else {
            this.noOptionMsg = false;
        }

        this.isLoading = false;
        return ret;
    }

    filter(operator, filterValue) {
        let ret = [];
        switch(operator) {
            case 'equal':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase() === filterValue;
                });
                break;
            case 'start':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) === 0;
                    // return o.label.toLowerCase().startsWith(filterValue);
                });
                break;
            case 'end':
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().endsWith(filterValue);
                });
                break;
            case 'contain' :
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) > -1;
                });
                break;
            default:
                ret =  _.filter(this.itemsList, (o)=> {
                    return o.label.toLowerCase().indexOf(filterValue) > -1;
                });
                break;
        }
        return ret;
    }

    onContainerClick(event: MouseEvent) { }

    onChange = (value: any[]) => { };

    onTouch = () => { };

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: any): void {
        this.initValue(value);
        
        if (this.autocompleteInput) {
            this.autocompleteInput.nativeElement.blur()
        }
    }

    initValue(value: any): void {
        if (!value) {
            value = null;
        } else if (typeof value == 'string'|| typeof value=='number') {
            value = value;
        } else if (typeof(value.length)=='undefined') {
            value = value;
        }
        this._value = value;
        this.populateSelected();
    }
    
    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: any[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue() {
        // this.writeValue(_.map(this.selectedItems, (o) => o));
        let selectedValue = null;
        if(this.objectOption) {
            selectedValue = this.selectedItem ? this.selectedItem : null;
        } else {
            selectedValue = this.selectedItem ? this.selectedItem.id : null;
        }
        this.writeValue(selectedValue);
        this.onChange(this._value);
        // this.onTouch();
    }

    private populateSelected() {
        if (this.itemsList && this._value) {
            this._populated = true;
        }
    }


    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    selected(event: MatAutocompleteSelectedEvent): void {
        // check if value not yet there
        // let o = _.find(this.selectedItems, { id: event.option.value });
        // if (!o) {
            // this.selectedItems = event.option.value; 
        // }

        this.selectedItem = event.option.value; 
        this.updateValue();

        this.onSelect.emit(this.selectedItem);
    }

    displayFn(option: OptionItem) {
        return option && option.label ? option.label : '';
    }

    keyPress(event) {
        if(!this.ready) {
            event.preventDefault()
        }
    }

    clearControl() {
        this.fieldCtrl.setValue(null);
        this.autocompleteInput.nativeElement.value = null;
        this.onClear.emit(true);
    }

    loadField() {
        let proms = [];
        if(this._value) {
            // if the value is an OptionItem(object)
            if(this._value.id && this._value.label) {
                this.fieldCtrl.setValue(this._value);
                this.selectedItem = this._value; 
            } else {// if the value is an id
                    // first request
                let params = {
                    id: this._OptionsColumns.id,
                    label: this._OptionsColumns.label
                };

                if(this._extraParameters) {
                    params['parameters'] = JSON.stringify(this._extraParameters);
                }
                this.parametersData = {
                    params: params
                };
    
                proms.push(
                    this.dataService.getAsPromise(`lists/${this._endpoint}/${this._value}`, this.parametersData).then(res => {
                        if(res && res.data) {
                            this.initialValue = [res.data];
                            let current_value = res.data;
                            this.fieldCtrl.setValue({
                                id: current_value.id,
                                label: current_value.label
                            });
                            this.selectedItem ={
                                id: current_value.id,
                                label: current_value.label
                            }; 
                        }
                    })
                );
            }

            Promise.all(proms).then(res => {
                this.updateValue();
            })

        } else {
            this.fieldCtrl.setValue(null);
        }
    }

    inputGetValue(event){
        if(!event.target.value){
            this.inputHasValue.emit(false);
        }
    }
}

