import { Component, Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'grid-preview-dialog',
  templateUrl: './grid-preview-dialog.component.html',
  styleUrls: ['./grid-preview-dialog.component.scss'],
})
export class GridPreviewDialog {
  public btn_save_disabled: boolean = false;
  public loader: boolean = false;
  public calendar = null;
  public loading = false;
  public headerWidth = [];

  constructor(
    public dialogRef: MatDialogRef<GridPreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translate: TranslateService,
  ) {
    if(data && data.data) {
      this.buildCalendar(data.data);
      this.setHeaderWidth();
    }
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  buildCalendar(data) {
    let calendar = [];
    if(data) {
      data.forEach((s , key)=> {
        let row = [];
        if(key === 0) { // header
          s.forEach((cell, index) => {
            // if(index == 0 || cell.text !== null) {
            // if(index == 0) {
              row.push(cell);
            // }
          });
        } else { // body
          s.forEach((cell , index) => {
            // if((index == 0  && cell.text !== null) || (data[0][index]['text'] !== null && data[key][0]['text'] !== null)) { // display the cell only if the header has value, and first column cell has value
              row.push(cell);
            // }
          });
        }
        if(row.length) { 
          calendar.push(row);
        }
      })
    }
    this.calendar = calendar;
  }


  setHeaderWidth() {
    let headerWidth = [];
    if(this.calendar) {
      let header = this.calendar[0];
      let originalWidth = [];
      header.forEach((h, index )=> {
        let celWidth = this.cellWidth(h);
        originalWidth.push(celWidth)
      });

      let finalWidth = [];
      const nnullable = originalWidth.filter(element => {
        return element !== null;
      });
      const nullable = originalWidth.filter(element => {
        return element === null;
      });
      
      originalWidth.forEach(h => {
        if(h !== null) {
          finalWidth.push(h);
        } else {
          let w = 100 - nnullable.reduce((a, b) => a + b, 0);
          finalWidth.push(w / nullable.length);
        }
      });
      headerWidth = finalWidth;
    }
  
    this.headerWidth = headerWidth;
  }

  cellWidth(cell) {
    if(cell.settings && cell.settings.width) {
      return cell.settings.width;
    }
    return null;
  }
}
