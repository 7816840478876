import { PusherService } from './services/pusher.service';
import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';
import { PwaService } from './services/pwa.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'qualiteam_webapp';
  authenticated : boolean = false;

  constructor(
    public auth: AuthenticationService, 
    private pusher:PusherService, 
    public pwa:PwaService
    ) {
    this.auth.currentAuthUser.subscribe( user=>{
      this.authenticated = user && user.exists() ? true : false;    
    })
  } 

  public updateApp() {
    console.log('Updating app!');
    window.location.reload();
  }

}