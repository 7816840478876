import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthUser } from 'src/app/models/AuthUser';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'footermenu',
  templateUrl: './footermenu.component.html',
  styleUrls: ['./footermenu.component.scss']
})
export class FooterMenuComponent implements OnInit {
  @Input() separator : string = ' - ';

  user : User = null;
  authUser : AuthUser = null;
  ready = false;
  public footerMenu : any[] = [];


  constructor(
    private navigation : NavigationService,
    private auth: AuthenticationService
  ) { 

    this.auth.currentAuthUser.subscribe(user=>{
      this.authUser = user;
      this.footerMenu = this.navigation.getMenu('footer');
      this.ready = true;
    })
    this.auth.currentUser.subscribe(user=>{
      this.user = user;
    })
  }

  ngOnInit() {


  }

  isItemVisible(item = null) {
    if (item) {
      

      let hasRole = false;
      let hasPerm = false;
      if (item.roles) {
        hasRole = this.authUser && this.authUser.hasRole(item.roles);
      } else {
        hasRole = true;
      }

      if (item.permission) {
        hasPerm = this.authUser && this.authUser.hasPermission(item.permission);
      } else {
        hasPerm = true;
      }

      return hasPerm && hasRole;
    }
  }

  
}
