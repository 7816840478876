import { FileServer } from '../../../services/fileserver.service';
import { PasswordValidators } from 'ngx-validators';
import { User } from '../../../models/User';
import { DataService } from '../../../services/data.service';

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class EditUserDialog {
  public loader = false;
  public form: UntypedFormGroup;
  public mode = null;
  public item: User = null;
  public roles: any[] = ['super-admin'];
  protected server_errors = null;
  public imagePreview = null;
  // protected avatar = null;
  protected tabs_fields = {
    'account': ['email', 'password', 'password_confirmation'],
    'infos': ['firstname', 'lastname', 'gender', 'mobile'],
    // 'avatar': ['avatar']
  }
  protected save_clicked = false;
  public show_disabled = false;
  public editable = true;

  constructor(
    public dataService: DataService,
    private translate: TranslateService,
    public fs: FileServer,
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {


    this.item = data.item;
    if (this.item.exists()) {
      this.item['password'] = '--NOCHANGE--';
      this.item['password_confirmation'] = '--NOCHANGE--';
    }

    if(data.show_disabled) {
      this.show_disabled = true;
    }


    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      lastname: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(128)]),
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      password_confirmation: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      gender: new UntypedFormControl('', [Validators.required]),
      mobile: new UntypedFormControl('', [, Validators.maxLength(16)]),
      newsletter: new UntypedFormControl('', []),
      disabled: new UntypedFormControl('', []),
    }, [PasswordValidators.mismatchedPasswords('password', 'password_confirmation')]);


    this.form.patchValue(this.item);
    this.mode = this.item.exists() ? 'update' : 'new';

    if(data.editable!=null) {
      this.editable = data.editable;
      if(this.editable == false){
        this.form.disable();
      }
    }
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.save_clicked = true;
    this.resetServerErrors();


    if (this.form.valid) {
      this.loader = true;
      let formData = this.form.value;

      if (this.mode == 'update') {

        this.dataService.putAsPromise(`account/${this.item.id}`, formData)
          .then(res => {
            this.dialogRef.close('bt_save');
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            console.log('ERROR', error)
          })

      } else {
        this.dataService.postAsPromise(`admin/users`, formData)
          .then(res => {
            this.item.import(res.data.id);
            this.dialogRef.close('bt_save');
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            console.log('ERROR', error);
            this.setServerErrors(error);
          })
      }

    } else {
      console.log('form not valid')
    }
  }

  // uploadAvatar() {
  //   return new Promise((resolve, reject) => {
  //     const formData = { 'avatar': this.imagePreview };
  //     this.dataService
  //       .putAsPromise(`admin/users/${this.item.id}/avatar`, formData)
  //       .then(res=>{
  //         resolve(res);
  //       })
  //       .catch(err=>{
  //         reject(err);
  //       })
  //   })
  // }

  tabError(tab) {
    if (!this.tabs_fields[tab] || !this.save_clicked) {
      return 0;
    }
    let n = 0;
    this.tabs_fields[tab].forEach(f => {
  
      if (!this.form.controls[f].valid) {
        n++;
      }
    });
    return n;
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.fields) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }
  resetServerErrors() {
    if (this.server_errors) {
      for (let i in this.server_errors.fields) {
       // this.form.controls[i].setErrors({ serverError: false });
        this.form.controls[i].markAsPristine();
        this.form.controls[i].markAsUntouched();
      }

      this.server_errors = null;
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.fields && this.server_errors.fields[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.fields[controlName].replace(/\./gi, ''));
    }
    return null;
  }

  // onImageChange(event) {
  //   console.log('onImageChange', event)
  //   this.avatar = event.target.files[0];
  //   this.imagePreview = null;
  //   this.getImagePreview(this.avatar);
  // }

  // getImagePreview(file: File) {
  //   const self = this;
  //   const reader: FileReader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {

  //     this.imagePreview = reader.result;

  //   };

  // }

}
