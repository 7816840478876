import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthUser } from './../models/AuthUser';
import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError, BehaviorSubject, from, empty } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';




@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private auth: AuthenticationService,
        private translate: TranslateService,
        private snack: MatSnackBar,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

     
        return next.handle(this.addToken(request)).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            return this.handle401Error(request, next);
                    }

                }
                return throwError(err);
            })
        );
    }


    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // handle401Error
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return from(this.auth.refreshToken())
                .pipe(
                    switchMap((user: AuthUser) => {
                        this.isRefreshingToken = false;
                        this.tokenSubject.next(user.access_token);
                        // replaying request with token
                        return next.handle(this.addToken(request));
                    }),
                    catchError(err => {
                        if (typeof(err.statusText)=='undefined') {
                            this.auth.logout();
                        } else {
                            this.snack.open( this.translate.instant('STATIC.ERROR.'+err.statusText),null, {duration:3000});

                        }
                        return EMPTY;
                      }),
                    finalize(() => {
                       this.isRefreshingToken = false;
                    })  
                )
        } else {
            return this.tokenSubject.pipe(
                filter(token => {
                    return token != null
                }),
                take(1),
                switchMap(token => {
                    // replaying old request with token
                    return next.handle(this.addToken(request));
                })
            );
        }
    }


    protected addToken(request: HttpRequest<any>) {
        // add authorization header with jwt token if available

        let currentUser = this.auth.getCurrentAuthUser();
        if (currentUser && currentUser.exists() && currentUser.access_token) {
           // adding bearer', `Bearer ${currentUser.access_token}

            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.access_token}`,
           //         'Content-Type': 'application/json',
                }
            });
        }
        return request;
    }
}