import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './../models/User';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Location } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class FileServer {
  protected fs_images = environment.fileserver.images;
  protected api_root =environment.api.root;

  constructor(
    protected location: Location,
    private http: HttpClient
  ) { }

  getImage(path) {
    return this.location.normalize(this.fs_images + path);
  }

  getAvatar(user: User | any, size = null) {
    if (!user.avatar) {
      return '';
    }
    let path = user.avatar;
    if (path) {
      if (size) {
        path = path.replace('/avatar.', `/${size}.`);
      }
      return this.location.normalize(this.fs_images + path);
    }
  }

  
  downloadFile(path, filename = null, mime = null, params = {}) {
    let url = this.location.normalize(`${this.api_root}/`+path)
    return new Promise((resolve, reject) => {
      let headers = {};
      if (mime) {
        headers['Content-Type'] = mime;
      }
      this.http.get(url, {
        params: params,
        headers: new HttpHeaders(headers), responseType: 'blob', observe: 'response'
      })
        .toPromise()
        .then(data => {
          filename = data.headers.get('Content-Filename');
          const file = mime ? new Blob([data.body], { type: mime }) : new Blob([data.body]);
          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();
          resolve(true);
        }).catch(error => {
          reject(error)
        });

    });
  }

  download(path, filename = null, mime = null, params = {}) {
    let url = this.getImage(path);
    if (!filename) {
      filename = path.split('/').pop()
    }
    return new Promise((resolve, reject) => {
      let headers = {};
      if (mime) {
        headers['Content-Type'] = mime;
      }
      this.http.get(url, {
        params: params,
        headers: new HttpHeaders(headers), responseType: 'blob'
      })
      .toPromise()
        .then(data => {
          const file = mime ? new Blob([data], { type: mime }) : new Blob([data]);

          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();
          resolve(true);
        }).catch(error => {
          reject(error)
        });
    });
  }
}
