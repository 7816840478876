import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'dashboard-stepper',
  templateUrl: './dashboard-stepper.component.html',
  styleUrls: ['./dashboard-stepper.component.scss']
})
export class DashboardStepperComponent implements OnInit {

  ready = false;
  _score = null;
  min = 0;
  max =4;
  steps = [];
  direction = 'asc';

  @Input() 
  get score() {
    return this._score;
  }
  set score(score) {
    if(score) {
      this._score = score;
      this.ready = true;
    } else {
      this._score = null;
      this.ready = false;
    }
  }

  constructor(private auth:AuthenticationService) {
    this.auth.currentAuthUser.subscribe((user) => {
      if (user) {
        if(user.client_settings){

          this.max = user.client_settings['max'] ? user.client_settings['max'] : null;
          this.min = user.client_settings['min'] ? user.client_settings['min'] : null;
          this.direction = user.client_settings['direction'];

          if(user.client_settings['labels'] && user.client_settings['labels'].length !=0){
            if(this.direction == 'asc'){
              for(let i = user.client_settings['min']; i<= user.client_settings['max']; i++){
                this.steps.push(user.client_settings['labels'][i]);
              } 
            }else if(this.direction == 'desc'){
              for(let i = user.client_settings['max']; i<= user.client_settings['min']; i++){
                this.steps.push(user.client_settings['labels'][i]);
              } 
            }
                
          }

          if(user.client_settings['direction'] && user.client_settings['direction'] == 'desc'){
              this.steps.reverse();
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
