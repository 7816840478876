import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    HostListener
  } from "@angular/core";
  import {
    ConnectedPosition,
    Overlay,
    OverlayRef
  } from "@angular/cdk/overlay";
  
  import { Subject } from "rxjs";
  import { TemplatePortal } from "@angular/cdk/portal";
  import { PopoverService } from "./popover.service";
  
  @Directive({
    selector: "[popoverTrigger]"
  })
  export class PopoverDirective implements OnDestroy, OnInit {
    @Input()
    popoverTrigger!: TemplateRef<object>;
  
    @Input()
    closeOnClickOutside: boolean = false;
  
    private unsubscribe = new Subject();
    private overlayRef!: OverlayRef;
  
    constructor(
      private elementRef: ElementRef,
      private overlay: Overlay,
      private vcr: ViewContainerRef,
      private popoverService: PopoverService
    ) {}
  
    ngOnInit(): void {
      //this.createOverlay();
      // this.popoverService.getState().subscribe(resp => {
      //   if (resp) {
      //     this.detachOverlay();
      //   }
      // });       
    }
  
    ngOnDestroy(): void {
      this.detachOverlay();
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  
    @HostListener("mouseover") onMouseOver() { 
      this.createOverlay();
      this.attachOverlay();              
    }

    @HostListener("mouseleave") onMouseLeave() {
      this.detachOverlay();
    }
  
    private createOverlay(): void {

      const scrollStrategy = this.overlay.scrollStrategies.block();
      const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      } as ConnectedPosition]);
  
      //if you need use this directive with a backdrop, 
      //set 'hasBackdrop' to true 
      //probably it will be reqired for the popover container to have:
      // a button for closing the overlay
      // to attach overlay on mouseclick event
      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy,
        hasBackdrop: false,
        backdropClass: ""
      });
  
      // this.overlayRef
      //   .backdropClick()
      //   .pipe(takeUntil(this.unsubscribe))
      //   .subscribe(() => {
      //     if (this.closeOnClickOutside) {
      //       this.detachOverlay();
      //     }
      //   });
      
    }
  
    private attachOverlay(): void {
  
      if (!this.overlayRef?.hasAttached()) {
        const periodSelectorPortal = new TemplatePortal(
          this.popoverTrigger,
          this.vcr
        );
  
        this.overlayRef?.attach(periodSelectorPortal);
      }
    }
  
    private detachOverlay(): void {
      if (this.overlayRef?.hasAttached()) {
        this.overlayRef.detach();
        this.overlayRef.dispose();
      }
      
    }
  }
  