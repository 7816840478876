import { Component, Inject} from '@angular/core';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'grid-editor-dialog',
  templateUrl: './grid-editor-dialog.component.html',
  styleUrls: ['./grid-editor-dialog.component.scss'],
})
export class GridEditorDialog {
  public btn_save_disabled: boolean = false;
  public loader: boolean = false;
  public item = null;
  public mode = null;
  public form: FormGroup;
  public loading = false;
  protected save_clicked = false;
  public server_errors = null;
  public from = null;
  public cell = null;
  protected withSettings: boolean = null;
  protected settings = null;
  public gridMode: string = null; // readonly | editable | fulleditable
  public placeholder: string = this.translate.instant("GRID_EDITOR_DIALOG.EDITOR_PLACEHOLDER")

  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],

      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],

      [{ 'color': [] }],
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<GridEditorDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translate: TranslateService,
  ) {
    this.form = new FormGroup({
      text: new FormControl(null,[]),
    }, {
      updateOn: "change"
    });

    if(data && data.cell) {
      this.cell = data.cell;
    }

    if(data && typeof data.withSettings !== 'undefined') {
      this.withSettings = data.withSettings;
    }

    if(data && typeof data.settings !== 'undefined') {
      this.settings = data.settings;
    }

    if(data && typeof data.gridMode !== 'undefined') {
      this.gridMode = data.gridMode;
    }

    if(data && typeof data.placeholder !== 'undefined' && data.placeholder) {
      this.placeholder = data.placeholder;
    }

    if(this.withSettings) {

      if(this.settings) {
        let settingsControls = {}
        if(this.settings.width) {
          settingsControls['width'] =  new FormControl(null,[]);
        }
        if(this.settings.editable) {
          settingsControls['editable'] =  new FormControl(true,[]);
        }
        this.form.addControl('settings',new FormGroup(settingsControls, {
          updateOn: "change"
        }));
      }
    }

    this.form.patchValue(this.cell);
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
   this.dialogRef.close({cell: this.form.value})
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.errors) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.errors && this.server_errors.errors[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.errors[controlName].toString().replace(/\./gi, ''));
    }
    return null;
  }

  setFocus(editor) { editor.setSelection(editor.getLength(), 0); }
}
