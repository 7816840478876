import { Component, Input, OnChanges, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit,OnChanges {

  @Input() min = 0;
  @Input() max = 4;
  @Input() score = 0;
  @Input() steps = [];
  @Input() hasLabels = true;

  constructor(private zone:NgZone) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.zone.run(()=>{});
  }

  getPercent() {
    if(this.score){
      return (this.score - this.min) * 100 / (this.max - this.min); 
    }else{
      return 0;
    }   
  }

}
